import React, { useState, useEffect } from 'react';
import { Grid, FormControl, Select, MenuItem } from '@mui/material';
import { useGlobalContext } from 'src/context/GlobalContext';
import { useAppointmentContext } from 'src/context/AppointmentContext';
import Skeleton from '@mui/material/Skeleton';
import { useThemeContext } from 'src/context/ThemeContext';
import { motion } from "framer-motion";

const TypeSelect = ({setCallback}) => {

    const { appointmentTypes, setSelectedType, selectedType, branch, isMobile } = useGlobalContext();

    const { isNewConcern, isExistingPatient } = useAppointmentContext();

    const { secondaryColor } = useThemeContext();
    
    const [type, setType] = useState({ID: ""});
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [filteredTypes, setFilteredTypes] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Filter types based on new concern and existing patient
        if(branch) filterTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedType, appointmentTypes, isNewConcern, isExistingPatient]);

    useEffect(() => {
        setCallback(() => () => {
          setType({ ID: "" });          
          setSelectedCategory("");      
        });
    }, [setCallback]);

    const pushException = (types, type) => {
        branch.client.includeBothExistingNew.forEach(value => {
            if (type.description.includes(value) && !types.find(value => value.ID === type.ID)) types.push(type);
        });
    }

    const filterTypes = () => {
        const types = [];
        appointmentTypes.forEach(type => {
            if (!isExistingPatient) {
                if(type.description.includes('Initial')) types.push(type);
                pushException(types, type);
            } else if (isExistingPatient) {
                if (isNewConcern) {
                    if (type.description.includes('Initial')) types.push(type);
                    pushException(types, type);
                }  else {
                    if (!type.description.includes('Initial')) types.push(type);
                }
            }
        });

        // Get unique categories
        const allCategories = types.map(type => type.category);
        const categories = branch.client.categoryOrder.map(category => {
            if (allCategories.includes(category)) return category;
            return null;
        }).filter(value => value !== null);

        // Add any types that were not included in the category order
        types.forEach(type => {        
            if (!categories.includes(type.category)) categories.push(type.category);
        });

        setCategories([...new Set(categories)]);
        setFilteredTypes(types);
        setLoading(false);
    }

    const handleChange = (id) => {
        if(id.target) {
            id = id.target.value;
        }
        const selectedType = appointmentTypes.find(value => value.ID === id);
        if (selectedType) {
            setType(selectedType);
            // Defer the global state update slightly to avoid blocking the UI
            setTimeout(() => {
                setSelectedType(selectedType);
            }, 100);
        }
    }

    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
    }
    
    return (
        <>{isExistingPatient != null && (!isExistingPatient || isNewConcern != null) && <div className='selected-type-select'>
            {loading ?
                <Skeleton variant="rounded" width={isMobile ? '100%' : '50%'} height={150} style={{margin: '14px auto'}}/>
                : 
                // If the client uses categories
                branch.client.useCategory ?
                <motion.div 
                    animate={{ height: 'auto' }} 
                    initial={{ height: 0 }} 
                    transition={{ ease: "easeInOut", duration: 0.5 }} 
                    style={{width: isMobile ? '100%' : '75%', margin: 'auto', textAlign:'center', overflow: 'hidden'}}
                >   
                    <h2>Select a Service</h2>
                    { selectedCategory ?
                    <div className="d-flex-center">
                        <div className='selected-desc light-text'>{selectedCategory}</div>
                        <div className='selected-change' style={{color: secondaryColor}} onClick={() => setSelectedCategory('')}>Change</div>
                    </div> 
                    :
                    <div>
                        <Grid container>
                            {categories.map((category, index) => (
                                <Grid key={index} item xs={12} md={6}>
                                    <div 
                                        className={`type-select-item ${selectedCategory === category ? 'selected' : ''}`}
                                        style={{
                                            backgroundColor: selectedCategory === category ? secondaryColor : 'white'
                                        }} 
                                        onClick={() => handleCategoryChange(category)}
                                    >
                                        <div className='type-select-item-text'>{category}</div>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </div>}
                    {selectedCategory && <div style={{width: isMobile ? '100%' : '75%', margin: 'auto', textAlign:'center', overflow: 'hidden'}}>   
                        <h2>Select an Appointment Type</h2>
                        <Grid container sx={{width: isMobile ? '100%' : '75%', margin: 'auto'}}>
                            {filteredTypes.filter(type => type.category === selectedCategory).map((appointmentType, index) => (
                                <Grid key={index} item xs={12}>
                                    <div 
                                        className={`type-select-item ${type.ID === appointmentType.ID ? 'selected' : ''}`}
                                        style={{
                                            backgroundColor: type.ID === appointmentType.ID ? secondaryColor : 'white'
                                        }} 
                                        onClick={() => handleChange(appointmentType.ID)}
                                    >
                                        <div className='type-select-item-text'>{appointmentType.description}</div>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </div>}
                </motion.div> :
                // If the client does not use categories use the select component
                <FormControl fullWidth variant="outlined">
                    <Select
                        displayEmpty
                        sx={{
                            backgroundColor: "white",
                            borderRadius: "5px",
                            height: "40px",
                            width: "70%",
                            margin: "0 auto",
                            border: "1px solid #D9D9D9",
                            fontSize: "14px",
                            "&:focus": { borderColor: "#D9D9D9" }
                        }}
                        value={type.ID}     
                        onChange={handleChange}
                    >
                        <MenuItem value={""} style={{ color: "#B5B5B5" }}>Appointment type</MenuItem>
                        {appointmentTypes && appointmentTypes.map((appointmentType, index) => (
                            <MenuItem key={index} value={appointmentType.ID} sx={{ whiteSpace: "unset" }}>
                                {appointmentType.description}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            }
        </div>}</>
    );
}

export default TypeSelect;

